import React from 'react'
import Silviu from './Silviu';
import Dudu from './Dudu';
function Contact() {
    return (
        <div className='justify-center'>
         <h1 className='flex-1 px2 mx-2  '> 
         <br></br>
         <b>
         SD Scandinavia Hovedkontor
         </b>
         <br></br>
         Hormestedvej 325, 9870 Sindal
         <br></br>
          Telefonnummer :  <b>+45 22 34 35 01</b>
         <br></br>
         Email: kontakt@sdscandinavia.dk
         <br></br>
         Åbningstider
         <br></br>
         Mandag - torsdag
         <br></br>
         8.00-16.00
         <br></br>
         Fredag
         <br></br>
         8.00-15.30
         <br></br>
         <br></br>
         <b>Vores medarbejdere:</b>
         <br></br>
         <Dudu  className='container mx-auto' /> 
         <Silviu  className='container mx-auto' /> 
        </h1>
        </div>
    )
}

export default Contact
import React from 'react'
import temporaryworkers from '../components/pictures/TemporaryWorkers.jpg';
import young from '../components/pictures/young.jpg';

function TemporaryWorkers() {
    return (
    <div className='flex justify-center items-center'>
        <img src={temporaryworkers} alt='workers' >
        </img>
        <img src={young} alt='youngworkers' >
        </img>
    </div>
    )
}

export default TemporaryWorkers
import React from 'react'
import Pavaje1 from '../components/pictures//Pavaje4.jpg';
import Pavaje2 from '../components/pictures/Pavaje5.jpg';
import Pavaje3 from '../components/pictures/Pavaje6.jpg';

function Pavaje() {
    return (
    <div className='flex space-x-4'>
         <b>Fortove </b>
        <img className='w-40 h-70 object-cover rounded-e' src={Pavaje1} alt='Pavaje1' >
        </img>
        <img className='w-40 h-70 object-cover' src={Pavaje2} alt='Pavaje2' >
        </img>
        <img className='w-40 h-70 object-cover' src={Pavaje3} alt='Pavaje3' >
        </img>
    </div>
    )
}

export default Pavaje
import React from 'react'
import Fabrica1 from '../components/pictures//P1.jpg';
import Fabrica2 from '../components/pictures/P2.jpg';

function Fabrica() {
    return (
    <div className='flex space-x-4'>
         <b>Fabrikker</b>
        <img className='w-40 h-70 object-cover rounded-e' src={Fabrica1} alt='rigips1' >
        </img>
        <img className='w-40 h-70 object-cover' src={Fabrica2} alt='rigips2' >
        </img>
    </div>
    )
}

export default Fabrica
import React from 'react'
import TemporaryWorkers from '../components/TemporaryWorkers';
function About() {
    return (
        <div className='justify-center'>
         <h1 className='flex-1 px2 mx-2  '> 
         Stiftet i 2022 af iværksætterne Silviu og Dudu, er SD Scandinavia en nyskabende start-up, der revolutionerer måden, virksomheder opfylder deres midlertidige bemandingsbehov. Med en mission om at skabe forbindelse mellem arbejdsgivere og dygtige medarbejdere, tilbyder SD Scandinavia en pålidelig og effektiv platform til adgang til højkvalificerede midlertidige medarbejdere.
Hos SD Scandinavia forstår vi udfordringerne, som virksomheder står over for, når det kommer til at finde kvalificerede og pålidelige midlertidige medarbejdere. Uanset om du har brug for ekstra støtte i travle perioder, kræver specialiserede færdigheder til kortvarige projekter eller ønsker at dække uforudsete fravær, så dækker vores platform dine behov.
<br></br>
<br></br>
<TemporaryWorkers  className='container mx-auto' /> 
<b>
Hvorfor vælge SD Scandinavia?
</b>
<br></br>
<br></br>
1.	Enestående talentbase: Vi er stolte af at opretholde et omfattende netværk af talentfulde og højt kvalificerede fagfolk fra forskellige brancher. Vores strenge udvælgelsesproces sikrer, at kun de mest kvalificerede personer er en del af vores talentbase. Uanset om det drejer sig om administrative og hotelbranchen, eller om det er håndværksfag og tekniske stillinger, kan vi matche dine specifikke krav med den rette midlertidige medarbejder.
<br></br>
<br></br>
2.	Problemfri matchningsproces: Vores avancerede matchningsalgoritme fjerner gættearbejdet ved at finde den ideelle midlertidige medarbejder til din virksomhed. Ved at analysere jobkrav, færdigheder, tilgængelighed og præferencer forbinder vi dig hurtigt med passende kandidater, der besidder den ekspertise, du har brug for. Denne strømlinede proces sparer dig tid og kræfter, så du kan fokusere på dine kerneforretninger.
<br></br>
<br></br>
3.	Gennemsigtighed og fleksibilitet: SD Scandinavia opererer på en gennemsigtig og fleksibel model. Du har fuld kontrol over valget af den rette midlertidige medarbejder til din opgave. Gennemse detaljerede profiler, gennemgå tidligere arbejdserfaring og få adgang til vurderinger og anmeldelser fra andre arbejdsgivere. Vi leverer de nødvendige værktøjer til effektiv kommunikation, så du kan samarbejde problemfrit med din midlertidige arbejdsstyrke.
<br></br>
<br></br>
Med SD Scandinavia får du adgang til et dynamisk fællesskab af dygtige fagfolk, der hjælper din virksomhed med at trives på en hurtigt skiftende marked. Uanset om du er en småvirksomhed, en start-up eller et etableret firma, er vores innovative platform designet til at imødekomme dine midlertidige bemandingsbehov med effektivitet og pålidelighed.
Opdag fremtiden for midlertidig arbejdskraftsstyring – vælg SD Scandinavia, din betroede partner for alle dine bemandingsbehov. Kom i gang i dag og oplev fleksibiliteten og bekvemmeligheden ved vores exceptionelle løsninger til midlertidige medarbejdere.
        </h1>
        </div>
    )
}

export default About
import React from "react";
const footerYear = new Date().getFullYear();
function Footer() {
  return (
    <footer className="footer p-10 bg-gray-700 text-primary-content footer-center">
      <div>
          <p>Copyright &copy;{footerYear} All rights reserved {footerYear}</p>
          <div className="flex items-center justify-center h-16">
            Telefonnummer: +45 22 34 35 01
          </div>
          <p>CVR : 43395882</p>
      </div>
    </footer>
  );
}

export default Footer;
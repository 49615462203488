import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import imageGroup1 from './pictures/POZAGRUP1.jpg';
import imageGroup2 from './pictures/POZAGRUP2.jpg';
import imageGroup3 from './pictures/POZAGRUP3.jpg';
import logo1 from './pictures/arbejdsmiljo.png';
import logo2 from './pictures/brunder.jpg';
import logo3 from './pictures/dansk.png';
import logo4 from './pictures/HLrent_logo.png';
import logo5 from './pictures/minKobmand.png';
import logo6 from './pictures/VismaDinero.png';
import "react-alice-carousel/lib/alice-carousel.css";
function LoginPage(){
return(
  <>
  <div className="pageContainer ">
    <header>
      
      <p className="pageHeader"><b> <p className="text-3xl">Vi har altid et godt vikarjob til dig</p></b></p>
    </header>

<p className="body">
Hos SD Scandinavia får du professionel støtte til din mellemstore virksomhed gennem et af landets førende og mest erfarne vikarbureauer.
<br></br>
 Med lokalafdelinger over hele landet er vi ikke blot bekvemt placeret tæt på dig, men også i nærheden af virksomhederne i dit lokalområde. Denne strategiske positionering giver os de bedste muligheder for at finde vikararbejde, der passer perfekt til dine virksomhedsbehov. 
 <br></br>
 Vores ekspertise og omfattende netværk sikrer en problemfri og skræddersyet tilgang til at imødekomme dine behov for arbejdskraft på bedste vis.</p>
 <div>
  <AliceCarousel 
        autoPlayInterval={3000}
        autoPlayDirection="rtl"
        autoPlay={true}
        fadeOutAnimation={true}
        mouseTrackingEnabled={true}
        disableAutoPlayOnAction={false}>
  <p> <img src={imageGroup1} className="slidering"></img></p>
  <p> <img src={imageGroup3} className="slidering"></img></p>
  </AliceCarousel>
 </div>
 <p className="text-3xl font-black text-center">SAMARBEJDSPARTNERE</p>
 <div>
  <AliceCarousel
        autoPlay
        autoPlayControls
        autoPlayStrategy="none"
        autoPlayInterval={3000}
        animationDuration={1000}
        animationType="slide"
        infinite
        touchTracking={false}
        disableDotsControls
        disableButtonsControls>
  <div > <img src={logo1} className="slidering"></img></div>
  <div > <img src={logo2} className="slidering"></img></div>
  <div > <img src={logo3} className="slidering"></img></div>
  <div > <img src={logo4} className="slidering"></img></div>
  <div > <img src={logo5} className="slidering"></img></div>
  <div > <img src={logo6} className="slidering"></img></div>
  </AliceCarousel>
 </div>
  </div>
</>
);
    
 
}
export default LoginPage;
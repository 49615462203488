import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Logo from "./Logo";
function Navbar({ title }) {
  return (
    <nav
      className="navbar mb-12 shadow-lg bg-neutral 
      text-neutral-content"
    >
      <div className="container mx-auto">
        <div className="flex-none px-2 mx-2">
          <Logo className="inline pr-2 text-3xl" />
          <Link to="/" className="text-lg font-bold align-middle">
            {title}
          </Link>
        </div>
        <div className="flex items-center justify-center h-16">
            <b> Telefonnummer: +45 22 34 35 01</b>
          </div>
        <div className="flex-1 px2 mx-2 ">
          <div className="flex items-center justify-center h-16">
            <Link to="/vikar" className="btn btn-ghost btn-sm rounded-btn">
              Vikar
            </Link>
            <Link to="/bygning" className="btn btn-ghost btn-sm rounded-btn">
              Bygning
            </Link>
          </div>
          <div className="flex justify-end">
            <Link to="/" className="btn btn-ghost btn-sm rounded-btn">
              Hjemmeside
            </Link>
            <Link to="/about" className="btn btn-ghost btn-sm rounded-btn">
              Om side
            </Link>
            <Link to="/contact" className="btn btn-ghost btn-sm rounded-btn">
              Kontakt side
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}
Navbar.defaultProps = {
  title: "SD Scandinavia",
};
Navbar.propTypes = {
  title: PropTypes.string,
};

export default Navbar;

import React from "react";
import Rigips from "./Rigips";
import Zidarie from "./Zidarie";
import Pavaje from "./Pavaje";
import Glet from "./Glet";
function Constructi() {
  return (
    <div className="justify-center">
      <h1 className="flex-1 px2 mx-2  ">
       
        <br></br>
        <br></br>
        <Rigips className="container mx-auto" />
        <br></br>
        <br></br>
        <Zidarie className="container mx-auto" />
        <br></br>
        <br></br>
        <Pavaje className="container mx-auto" />
        <br></br>
        <br></br>
        <Glet className="container mx-auto" />
        <br></br>
        <br></br>
      </h1>
    </div>
  );
}

export default Constructi;

import React from 'react'
import Glet1 from '../components/pictures//Glet.jpg';
import Glet2 from '../components/pictures/Glet2.jpg';

function Glet() {
    return (
    <div className='flex space-x-4'>
         <b>Spartelmasse</b>
        <img className='w-40 h-70 object-cover rounded-e' src={Glet1} alt='rigips1' >
        </img>
        <img className='w-40 h-70 object-cover' src={Glet2} alt='rigips2' >
        </img>
    </div>
    )
}

export default Glet
import React from 'react'
import DuduPoza from '../components/pictures/Dudu.jpg';

function Dudu() {
    return (
    <div className='flex justify-center items-center'>
        <div className='rounded overflow-hidden shawo-lg'>
        <div className="flex flex-column">
        <img className='w-40 h-70 object-cover' src={DuduPoza} alt='Dudu' >
        </img>
    </div>
    <div>
    <b>Administrerende Direktør</b>
    <br></br>
    <b>Iuliu Dudu Pop</b>
    <br></br>
    <b>+45 22 34 35 01</b>
    </div>
    </div>
    </div>
    )
}

export default Dudu
import React from 'react'
import Silviupoza from '../components/pictures/Silviu.jpg';

function Silviu() {
    return (
    <div className='flex justify-center items-center'>
        <div className='rounded overflow-hidden shawo-lg'>
        <div className="flex flex-column">
        <img className='w-40 h-70 object-cover' src={Silviupoza} alt='Silviu' >
        </img>
    </div>
    <div>
    <b>Silviu Florescu</b>
    <br></br>
    <b>+45 28 68 89 65</b>
    </div>
    </div>
    </div>
    )
}

export default Silviu
import React from 'react'
import Agricultura1 from '../components/pictures//POZAGRUP1.jpg';

function Agricultura() {
    return (
    <div className='flex space-x-4'>
         <b>Landbrug </b>
        <img className='w-40 h-70 object-cover rounded-e' src={Agricultura1} alt='zidarie' >
        </img>
    </div>
    )
}

export default Agricultura
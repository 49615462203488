import React from 'react'
import Zidarie1 from '../components/pictures//Pavaje.jpg';
import Zidarie2 from '../components/pictures/Pavaje2.jpg';
import Zidarie3 from '../components/pictures/Zidarie3.jpg';

function Zidarie() {
    return (
    <div className='flex space-x-4'>
         <b>Murværk </b>
        <img className='w-40 h-70 object-cover rounded-e' src={Zidarie1} alt='zidarie' >
        </img>
        <img className='w-40 h-70 object-cover' src={Zidarie2} alt='rigips2' >
        </img>
        <img className='w-40 h-70 object-cover' src={Zidarie3} alt='rigips2' >
        </img>
    </div>
    )
}

export default Zidarie
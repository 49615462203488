import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LoginPage from "./components/LoginPage";
import About from "./components/About";
import Contact from "./components/Contact";
import NotFound from "./pages/NotFound";
import Bygning from "./components/Constructi";
import Vikar from "./components/Vikar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
function App() {
  return (
    <Router>
      <div className="flex flex-col justify-between shadow-lg bg-neutral  h-screen">
        <Navbar />
        <main className="container  mx-auto px-3 pb-12">
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/*" element={<NotFound />} />
            <Route path="/bygning" element={<Bygning />} />
            <Route path="/vikar" element={<Vikar />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React from 'react'
import Rigips1 from '../components/pictures//Rigips1.jpg';
import Rigips2 from '../components/pictures/Rigips2.jpg';

function Rigips() {
    return (
    <div className='flex space-x-4'>
         <b>Gipsplader </b>
        <img className='w-40 h-70 object-cover rounded-e' src={Rigips1} alt='rigips1' >
        </img>
        <img className='w-40 h-70 object-cover' src={Rigips2} alt='rigips2' >
        </img>
    </div>
    )
}

export default Rigips
import React from 'react'
import MuncaNecalificata1 from '../components/pictures//Pavaje4.jpg';

function MuncaNecalificata() {
    return (
    <div className='flex space-x-4'>
         <b>Ufaglært arbejde </b>
        <img className='w-40 h-70 object-cover rounded-e' src={MuncaNecalificata1} alt='Pavaje1' >
        </img>

    </div>
    )
}

export default MuncaNecalificata
import React from "react";
import Demolari from "./Demolari";
import Fabrica from "./Fabrica";
import Agricultura from "./Agricultura";
import MuncaNecalificata from "./MuncaNecalificata";
function Curatenie() {
  return (
    <div className="justify-center">
      <h1 className="flex-1 px2 mx-2  ">
        Stiftet i 2022 af iværksætterne Silviu og Dudu, er SD Scandinavia en
        nyskabende start-up, der revolutionerer måden, virksomheder opfylder
        deres midlertidige bemandingsbehov. Med en mission om at skabe
        forbindelse mellem arbejdsgivere og dygtige medarbejdere, tilbyder SD
        Scandinavia en pålidelig og effektiv platform til adgang til
        højkvalificerede midlertidige medarbejdere. Hos SD Scandinavia forstår
        vi udfordringerne, som virksomheder står over for, når det kommer til at
        finde kvalificerede og pålidelige midlertidige medarbejdere. Uanset om
        du har brug for ekstra støtte i travle perioder, kræver specialiserede
        færdigheder til kortvarige projekter eller ønsker at dække uforudsete
        fravær, så dækker vores platform dine behov.
        <br></br>
        <br></br>
        <Demolari className="container mx-auto" />
        <br></br>
        <br></br>
        <Fabrica className="container mx-auto" />
        <br></br>
        <br></br>
        <Agricultura className="container mx-auto" />
        <br></br>
        <br></br>
        <MuncaNecalificata className="container mx-auto" />
        <br></br>
        <br></br>
      </h1>
    </div>
  );
}

export default Curatenie;
